//......................................
//= PATHS
//......................................

$images 				:	'../images';
$fonts					:	'../fonts';


$kelsonbold				:	"kelson_sansbold";
$kelsonregular			:	"kelson_sansnormal";
$kelsonlight			:	"kelson_sanslight";
$calibriregular			:	"Conv_Calibri";


//COLORS
$darkpurple				:	#5f2b77;
$purple					:	#6d3b85;
$lightpurple			:	#6d3b85;
$white					:	#ffffff;
$black					:	#000000;
$darkgrey				:	#2c2c2c;
$grey					:	#4b4b4b;
$lightgrey				:	#d6d6d6;


//MEDIA QUERY
$media_max_1200 		:	'max-width:1200px';
$media_max_sm 			:	'max-width:991px';
$media_max_xs 			:	'max-width:767px';