@import 'reset';
@import 'variables';
@import 'fonts';
@import 'settings';
@import 'icons';

.container{max-width: 1200px;width: 100%;}

body{
	background: url(#{$images}/content/noise.png) repeat;
}

/**************************HEADER**************************/
header{
	position: absolute;
	top: 40px;
	z-index: 2;
	width: 100%;
	@media ($media_max_sm){
		top:0;
		position: fixed;
		z-index: 999;
	}
	.container{
		@media ($media_max_sm){
			padding:0;
		}
	}
	nav.navbar-inverse{
		background: $purple;
		padding: 19px 27px;
		@media ($media_max_sm){
			padding:19px 15px;
		}
		.navbar-header{
			margin-top: 10px;
			@media ($media_max_sm){
				margin:0;
			}
			img{
				max-width: 260px;
				width: 100%;
				@media ($media_max_sm){
					max-width: 240px;
				}
			}
			button{
				padding: 0;
				span{
					height: 2px;
					width: 30px;
					+ span{
						margin-top: 10px !important;
					}
				}
			}
		}
		.navbar-collapse{
			ul:not(.navbar-right){
				margin-top: 12px;
				position: absolute;
				left: 50%;
				transform: translate(-50%,0);
				margin-left: 30px;
				@media ($media_max_sm){
					position: initial;
					transform: translate(0);
					left:0;
					margin-left:0;
					margin-bottom: 10px;
					border-bottom: 1px solid $darkpurple;
				}
				li{
					padding: 0 16px;
					@media ($media_max_sm){
						padding:5px 0;
						text-align: center;
					}
					a{
						font-family: $kelsonregular;
						@include font-size(17,24);
						padding: 0;
						color:$white;
						text-transform: uppercase;
					}
					&.active{
						a{
							border-bottom: 2px solid $white;
						}
					}
				}
			}
			.navbar-right{
				margin:0;
				@media ($media_max_sm){
					margin:0 auto;
					width: auto;
					display: table;
				}
				li{
					@media ($media_max_sm){
						float: left;
					}
					a{
						font-family: $kelsonregular;
						@include font-size(15,24);
						color:$white;
						border:2px solid $white;
						padding: 10px;
						height: 48px;
						i{
							font-size: 24px;
							&.icon-mail{
								font-size: 21px;
								margin-right: 6px;
								margin-top: -3px;
								@media ($media_max_sm){
									margin:0;
								}
							}
						}
					}
					&:not(:first-child){
						padding-left: 8px;
						a{
							padding: 10px 14px;
							span{
								@media ($media_max_sm){
									display: none;
								}
							}
						}
					}
					&.lang{
						i{
							display: none;
							@media ($media_max_sm){
								display: inline-block;
							}
						}
					}
				}
			}
		}
	}
}
/**************************HEADER**************************/

/**************************PAGE-TOP**************************/
.page-top{
	position: relative;
	background: rgb(252,249,253);
	background: linear-gradient(180deg, rgba(252,249,253,1) 68%, rgba(247,238,250,1) 100%);
	&.home{
		padding-top: 126px;
		@media ($media_max_sm){
			padding-top: 100px;
		}
		.shield-container{
			text-align: right;
			padding-top: 10px;
			position: absolute;
			width: 100%;
			bottom: 0;
			@media ($media_max_xs){
				bottom: auto;
				top: 70px
			}
			p{
				&.shield{
					padding-bottom: 15px;
					font-family: $kelsonbold;
					@include font-size(18,22);
					color:$purple;
					i{
						padding-right: 10px;
						font-size: 30px;
					}
				}
			}
		}
	}
	.page-banner{
		background-size: cover !important;
		background-position: center !important;
		height: 276px;
		padding-top: 224px;
		@media ($media_max_sm){
			height: 176px;
			padding-top: 128px;
		}
		h2{
			font-family: $kelsonbold;
			@include font-size(30,36);
			color:$white;
			text-transform: uppercase;
			float: left;
			@media ($media_max_xs){
				@include font-size(24,30);
				float: none;
				position: absolute;
				left: 15px;
				bottom: 15px;
			}
		}
		a.back{
			font-family: $kelsonlight;
			@include font-size(13,16);
			color:$white;
			display: inline-block;
			background: $purple;
			float: right;
			padding: 7px 15px;
			i{
				font-size: 17px;
				color:$white;
				margin-right: 5px;
				margin-top: -3px;
			}
			@media ($media_max_xs){
				display:none;
			}
		}
		&.map{
			height: 495px;
			#map{
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 1;
			}
		}
	}
}
/**************************PAGE-TOP**************************/

/**************************CAROUSEL**************************/
.carousel{
	padding: 93px 0 0 0;
	height: 528px;
	@media ($media_max_sm){
		height: auto;
		padding: 0 0 0 0;
	}
	.carousel-inner{
		padding-bottom: 50px;
	}
	ol.carousel-indicators{
	    bottom: 20px;
	    left: 0;
	    width: 100%;
	    text-align: center;
	    margin: 0;
		li{
			border-color:$purple;
			height: 7px;
			width: 30px;
			border-radius: 5px;
			margin:0 2px !important;
			&.active{
				background: $purple;
			}
			@media ($media_max_xs){
				width:20px;
			}
		}
	}
	.slide-title{
		padding-top: 50px;
		@media ($media_max_sm){
			padding-top: 30px;
			text-align: center;
		}
		h1{
			font-family: $kelsonbold;
			@include font-size(45,50);
			color:$grey;
			margin-bottom: 10px;
			opacity: 0;
			@media ($media_max_sm){
				@include font-size(30,35);
			}
			span{
				display: block;
			}
		}
		p{
			font-family: $kelsonlight;
			@include font-size(20,24);
			color:$grey;
			opacity: 0;
		}
		a{
			font-family: $kelsonbold;
			@include font-size(13,16);
			color:$purple;
			display: inline-block;
			border:2px solid $purple;
			padding: 11px 15px;
			margin-top: 30px;
			opacity: 0;
			i{
				font-size: 21px;
				margin-right: 13px;
				margin-top: -3px;
			}
		}
	}
	.slide-image{
		figure{
			text-align: right;
			opacity: 0;
			@media ($media_max_sm){
				height: 200px;
				@include flex;
				align-items: center;
				justify-content: center;
			}
			img{
				max-width: 100%;
				max-height: 378px;
				@media ($media_max_sm){
					max-height: 200px;
				}
			}
		}
	}
	.item{
		&.active{
			h1{
				animation-name: fadeInRight;
			}
			p{
				animation-name: fadeInLeft;
			}
			a{
				animation-name: fadeInUp;	
			}
			.slide-image{
				figure{
					animation-name: fadeIn;
				}
			}
		}
	}
}
/**************************CAROUSEL**************************/

/**************************MAIN-CORPORATE**************************/
.main-corporate{
	padding:50px 0;
	h2{
		font-family: $kelsonbold;
		@include font-size(26,32);
		color:$grey;
		position: relative;
		margin-bottom: 34px;
		text-transform: uppercase;
		@media ($media_max_xs){
			@include font-size(22,25);
		}
		&:before{
			content:"";
			position: absolute;
			left: 0;
			bottom: -7px;
			max-width: 100px;
			width: 100%;
			height: 3px;
			background: rgb(109,59,133);
			background: linear-gradient(90deg, rgba(109,59,133,1) 50%, rgba(247,238,250,0) 100%);
		}
	}
	.corporate{
		height: 347px;
		background: $white url(#{$images}/content/main-corporate.jpg) no-repeat center left;
		background-size: auto 100%;
		padding:34px 15px 34px 284px;
		@media ($media_max_1200){
			padding-left: 200px;
		}
		@media ($media_max_sm){
			height:auto;
			background-position:center left -130px;
			padding-left:15px;
			margin-bottom:15px;
		}
		p{
			font-family: $kelsonlight;
			@include font-size(16,24);
			color:$grey;
			margin-bottom: 60px;
			display: -webkit-box;
		    -webkit-box-orient: vertical;
		    -webkit-line-clamp: 5;
		    overflow: hidden;
		    @media ($media_max_sm){
				height:auto;
			}
		}
		a{
			font-family: $kelsonbold;
			@include font-size(13,17);
			color:$purple;
			i{
				font-size: 8px;
				color:$purple;
				padding: 9.5px 8px 9.5px 11px;
				border:2px solid $purple;
				margin-right: 9px;
			}
		}
	}
	.announcements{
		background: $white;
		position: relative;
		.top{
			padding: 34px 30px 25px 44px;
			position: absolute;
			top: 0;
			z-index: 2;
			@media ($media_max_sm){
				padding-left:15px;
			}
			h2{
				margin:0;
			}
		}
		.swiper-container {
			width: 100%;
			height: 347px;
			padding-top: 92px;
			&:before{
				content:"";
				position: absolute;
				left: 0;
				width: 100%;
				height: 93px;
				background: $white;
				top: 0;
				z-index: 3;
				border-bottom: 1px solid #f1f1f1;
			}
		}
		.wrap{
			padding: 23px 20px 24px 44px;
			border-bottom: 1px solid #f1f1f1;
			@media ($media_max_sm){
				padding-left:15px;
			}
			p{
				font-family: $kelsonlight;
				@include font-size(16,20);
				color:$black;
				display: -webkit-box;
			    -webkit-box-orient: vertical;
			    -webkit-line-clamp: 2;
			    overflow: hidden;
			    height: 40px;
				&:first-child{
					height:auto;
					margin-bottom: 19px;
				}
				i{
					margin-right: 9px;
					color:$purple;
					font-size: 19px;
				}
			}
		}
	}
}

.swiper-container{
	.controls{
		ul{
			display: table;
			position: absolute;
			right: 28px;
			top: 36px;
			z-index: 3;
			@media ($media_max_xs){
				top: 32px;
				right: 10px;
			}
			li{
				float: left;
				display: inline-block;
				cursor: pointer;
				background: none;
				position: initial;
				border:2px solid $purple;
				@include transition;
				&:not(:last-child){
					width: 30px;
					height: 30px;
					text-align: center;
					border-color:$lightgrey;
					line-height: 1.8;
					margin: 0 2.5px;
				}
				&:last-child{
					margin-left: 12px;
					@media ($media_max_sm){
						width: 30px;
						height: 30px;
						margin-left: 2.5px;
					}
					i{
						display: none;
						font-size: 13px;
						color:$purple;
						@media ($media_max_sm){
							display: block;
						}
					}
					span{
						@media ($media_max_sm){
							display: none;
						}
					}
				}
				i{
					font-size: 10px;
					color:$lightgrey;
				}
				a{
					font-family: $kelsonbold;
					@include font-size(13,16);
					color:$purple;
					padding: 5px 11px;
					display: block;
					@media ($media_max_sm){
						padding: 5px 7px;
					}
				}
			}
		}
	}
}
/**************************MAIN-CORPORATE**************************/

/**************************MAIN-PRODUCT**************************/
.main-product-group{
	padding: 81px 0 89px 0;
	background: url(#{$images}/content/main-product-group-bg.jpg) no-repeat center;
	background-size: cover;
	h2{
		font-family: $kelsonbold;
		@include font-size(27,32);
		color:$white;
		position: absolute;
		top: 4px;
		@media ($media_max_xs){
			@include font-size(22,25);
		}
	}
	.wrap{
		padding: 30px 30px 22px 33px;
		background: $white;
		position: relative;
		figure{
			height: 210px;
			text-align: center;
			margin-bottom: 22px;
			img{
				max-height: 100%;
			}
		}
		.title{
			height: 48px;
			display: flex;
			align-items: center;
		}
		h3{
			font-family: $kelsonbold;
			@include font-size(20,24);
			color:$grey;
			margin-bottom: 4px;
			padding-right: 60px;
			display: -webkit-box;
		    -webkit-box-orient: vertical;
		    -webkit-line-clamp: 2;
		    overflow:hidden;
		    text-transform: uppercase;
		}
		i{
			&.icon-plus{
				font-size: 22px;
				color:$purple;
				padding: 11px;
				border:2px solid $purple;
				position: absolute;
				bottom: 22px;
				right: 30px;
				@include transition;
			}
		}
		&:hover{
			i{
				&.icon-plus{
					color:$white;
					background: $purple;
				}
			}
		}
	}
	.swiper-container{
		padding-top: 61px;
		.controls{
			ul{
				right: 0;
				top: 0;
				li{
					border:2px solid $white;
					&:not(:last-child){
						border-color:$white;
					}
					&:last-child{
						i{
							color:$white;
						}
					}
					i{
						color:$white;
					}
					a{
						color:$white;
					}
				}
			}
		}
	}
}
/**************************MAIN-PRODUCT**************************/

/**************************MAIN-REPRESENTATIONS**************************/
.main-representations{
	padding: 61px 0 55px 0;
	h2{
		font-family: $kelsonbold;
		@include font-size(27,32);
		color:$grey;
		position: absolute;
		top: 4px;
		text-transform: uppercase;
		@media ($media_max_xs){
			@include font-size(22,25);
		}
	}
	figure{
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 130px;
		padding: 0 10px;
		img{
			max-width: 100%;
		}
	}
	.swiper-container{
		padding-top: 61px;
		&:before{
			content:"";
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 178px;
			background: $white;
		}
		.swiper-wrapper{
			padding: 28px 0 20px 0;
		}
		.controls{
			ul{
				top: 0;
				right: 0;
				li{
					border:2px solid $purple;
					&:not(:last-child){
						border-color:#d6d6d6;
					}
					&:last-child{
						i{
							color:$purple;
						}
					}
					i{
						color:#d6d6d6;
					}
				}
			}
		}
	}
}
/**************************MAIN-REPRESENTATIONS**************************/

/**************************FOOTER**************************/
footer{
	background: $white;
	padding: 40px 0 0 0;
	@media ($media_max_sm){
		padding-top: 10px;
	}
	> .container{
		> .row{
			> div{
				padding: 0 15px;
				float: left;
				&.col-erd-2{
					width: 14%;
				}
				&.col-erd-2-5{
					width: 19.5%;
				}
				&.col-erd-3{
					width: 22.25%;
				}
				&.col-erd-3-5{
					width: 22%;
				}
				@media ($media_max_sm){
					width:100% !important;
				}
				> div{
					max-width: 300px;
					@media ($media_max_sm){
						margin:15px auto;
						text-align: center;
					}
				}
			}
		}
	}
	h4{
		font-family: $kelsonbold;
		@include font-size(18,24);
		color:$purple;
		margin-bottom: 20px;
		text-transform: uppercase;
	}
	.footer-menu{
		ul{
			li{
				font-family: $kelsonregular;
				@include font-size(14,18);
				&:not(:last-child){
					margin-bottom: 22px;
					@media ($media_max_sm){
						margin-bottom: 15px;
					}
				}
				a{
					font-family: $kelsonregular;
					@include font-size(14,18);
					color:$grey;	
				}
			}
		}
	}
	.e-bulten{
		@media ($media_max_sm){
			margin:0 auto;
		}
		p{
			font-family: $kelsonregular;
			@include font-size(14,20);
			color:$grey;
			margin-bottom: 35px;
		}
		.wrap{
			position: relative;
			input{
				padding: 6px 0;
				font-family: $kelsonregular;
				@include font-size(14,20);
				border-bottom: 2px solid $purple;
				width: 100%;
				color:$grey;
				background:none;
				padding-right: 35px;
				@include placeholder{
					color:$lightgrey;
				}
			}
			button{
				background: $purple;
				padding: 5px;
				position: absolute;
				right: 0;
				bottom: 6px;
				width: 26px;
				height: 26px;
				i{
					font-size: 14px;
					color:$white;
					margin-top: -7px;
				}
			}
		}
	}
	.contact{
		address{
			margin-top: -11px;
			font-family: $kelsonregular;
			@include font-size(14,40);
			color:$grey;
			margin-bottom: 17px;
		}
		ul{
			li{
				&:not(:last-child){
					margin-bottom: 14px;
				}
				a{
					font-family: $kelsonregular;
					@include font-size(14,18);
					color:$grey;
					padding-left: 35px;
					position: relative;
					i{
						position: absolute;
						left: 0;

						font-size: 24px;
						color:$purple;
					}	
				}
			}
		}
	}
	.bottom-bar{
		background: $darkpurple;
		margin-top: 46px;
		.row{
			> div{
				@media ($media_max_sm){
					padding:0;
				}
			}
		}
		p{
			font-family: $kelsonregular;
			@include font-size(13,16);
			color: $white;
			padding-top: 19px;
			@media ($media_max_sm){
				padding:19px 0;
				text-align: center;
			}
		}
		a{
			&.comments{
				padding: 19px 15px;
				font-family: $kelsonregular;
				@include font-size(13,16);
				color:$white;
				background: $lightpurple;
				display: block;
				text-align: center;
				i{
					font-size: 21px;
					color:$white;
					margin-right: 9px;
					margin-top: -2px;
				}
			}
		}
		ul{
			display: table;
			padding: 13px 0;
			margin:0 auto;
			li{
				float: left;
				display: inline-block;
				&:not(:last-child){
					padding-right: 25px;
				}
				a{
					i{
						font-size: 20px;
						color:$white;
					}
					img{
						height: 30px;
					}
				}
			}
		}
	}
}
/**************************FOOTER**************************/

.wrapper{
	padding: 87px 0;
	@media ($media_max_sm){
		padding:40px 0;
	}
}

/**************************CORPORATE**************************/
.corporate{
	h5{
		font-family: $kelsonbold;
		@include font-size(30,36);
		color:$purple;
		margin-bottom: 25px;
		text-transform: uppercase;
		@media ($media_max_xs){
			@include font-size(24,30);
		}
	}
	p{
		font-family: $kelsonregular;
		@include font-size(15,28);
		color:$grey;
	}
}
/**************************CORPORATE**************************/

/**************************REPRESENTATIONS**************************/
.representations{
	.wrap{
		background: $white;
		margin-bottom: 30px;
		figure{
			height: 210px;
			border-bottom: 1px solid #f0f0f0;
			padding: 40px 15px 25px 15px;
			position: relative;
			@include flex;
			align-items: center;
			justify-content: center;
			&:before{
				content:"";
				position: absolute;
				left: 0;
				bottom: -1px;
				width: 40%;
				background: $purple;
				height: 1px;
			}
			img{
				max-height: 100%;
				max-width: 100%;
			}
		}
		section{
			padding: 30px 20px 25px 20px;
			text-align: center;
			min-height: 352px;
			h6{
				font-family: $kelsonbold;
				@include font-size(22,26);
				color:$darkgrey;
				margin-bottom: 16px;
			}
			p{
				font-family: $kelsonlight;
				@include font-size(15,24);
				color:$darkgrey;
				margin-bottom: 35px;
			    display: -webkit-box;
			    -webkit-box-orient: vertical;
			    -webkit-line-clamp: 7;
			    overflow: hidden;
			    @media ($media_max_xs){
			    	-webkit-line-clamp: inherit;
			    }
			}
			a{
				display: block;
				max-width: 260px;
				font-family: $kelsonlight;
				@include font-size(16,22);
				color:$darkgrey;
				padding: 14px;
				text-align: center;
				margin:0 auto;
				border-radius: 6px;
				border:1px solid #e7e7e7;
				@include transition;
				&:hover{
					border-color:$purple;
				}
				i{
					font-size: 22px;
					color:$purple;
					margin-right: 5px;
					margin-top: -3px;
				}
			}
		}
	}
}
/**************************REPRESENTATIONS**************************/

/**************************ANNOUNCEMENTS**************************/
.announcements{
	.panel{
		+ .panel{
			margin-top: 15px;
		}
		border-radius: 0;
		.panel-heading{
			background: none;
			border-radius: 0;
			padding: 0;
			a{
				font-family: $kelsonlight;
				@include font-size(15,24);
				color:$grey;
				display: block;
				position: relative;
				padding: 23px;
				padding-left: 216px;
				padding-right: 100px;
				@media ($media_max_xs){
					padding:15px;
				}
				span{
					position: absolute;
					&.calendar{
						display: inline-block;
						min-width: 184px;
						padding: 23px 23px 23px 37px;
						left: 0;
						top: 0;
						height: 100%;
						border-right: 1px solid #efefef;
						@media ($media_max_xs){
							position: initial;
							width: 100%;
							display: block;
							border-right: 0;
							padding: 0 0 15px 0;
							border-bottom: 1px solid #efefef;
							margin-bottom: 15px;

						}
						i{
							font-size: 19px;
							color:$purple;
							margin-right: 10px;
							margin-top: -3px;
						}
					}
					&.plus-minus{
						width: 75px;
						border-left: 1px solid #efefef;
						right: 0;
						top: 0;
						height: 100%;
						@include flex;
						align-items: center;
						justify-content: center;
						@media ($media_max_xs){
							height: 55px;
						}
						i{
							color:#efefef;
							font-size: 22px;
						}
					}
				}
			}
		}
		.panel-body{
			padding: 44px 37px;
			font-family: $kelsonlight;
			@include font-size(15,24);
			color:$grey;
			@media ($media_max_xs){
				padding: 20px 15px;
			}
		}
		&.active{
			.panel-heading{
				span{
					&.plus-minus{
						i{
							&:before{
								content:"\e900";
							}
						}
					}
				}
			}
		}
	}
}
/**************************ANNOUNCEMENTS**************************/

/**************************PRODUCTS**************************/
.products{
	.products-menu{
		.mobile-menu-title{
			display: none;
			margin-bottom: 15px;
			cursor: pointer;
			@media ($media_max_sm){
				display: block;
			}
			h3{
				font-family: $kelsonregular;
				@include font-size(15,24);
				color:$white;
				padding: 17px 20px;
				border:1px solid $purple;
				background: $purple;
				position: relative;
				padding-right: 45px;
				&:before{
					content:"\e902";
					font-family: 'icomoon';
					position: absolute;
					right: 20px;
					top: 50%;
					transform: translate(0,-50%);
					color:$white;
					font-size: 14px;
				}
			}
		}
		.panel-group{
			margin:0;
			.panel-heading{
				background: none;
				border:none;
				border-radius: 0;
				padding: 0;
			}
		}
		.main-panel-group{
			display: block;
			@media ($media_max_sm){
				display: none;
				margin-bottom: 15px;
				&.active{
					display: block;
				}
			}
			> .panel-default{
				border-radius: 0;
				box-shadow: 0 0 0 0;
				+ .panel{
					margin-top: 9px;
				}
				> .panel-heading{
					a{
						display: block;
						font-family: $kelsonregular;
						@include font-size(15,24);
						color:$grey;
						background: $white;
						padding: 16px 35px 16px 20px;
						position: relative;
						text-transform: uppercase;
						&:before{
							content: "\e916";
							font-family: 'icomoon';
							position: absolute;
							right: 20px;
							color:#dddddd;
							top: 50%;
							transform: translate(0,-50%);
						}
						&[aria-expanded=true]{
							&:before{
								content:"\e900";
							}
						}
					}
				}
				.panel-body{
					padding: 0;
					> .panel-group{
						.panel-default{
							border:none;
							margin:0 !important;
							&:not(:last-child){
								border-bottom: 1px solid #dddddd;
							}
							.panel-heading{
								a{
									display: block;
									font-family: $kelsonlight;
									@include font-size(14,20);
									color:$grey;
									padding: 13.5px 15px 13.5px 43px;
									position: relative;
									@include transition;
									&:before{
										content:"\e901";
										font-family: 'icomoon';
										font-size: 6px;
										color:#dddddd;
										position: absolute;
										left: 26px;
										top: 50%;
										transform: translate(0,-50%);
										@include transition;
									}
									&:after{
										content:"";
										position: absolute;
										left: 0;
										top: 0;
										width: 2px;
										height: 0;
										background: $purple;
										@include transition;
									}
									&:hover,&.active{
										background: #faf6fc;
										&:before{
											color:$purple;
										}
										&:after{
											height: 100%;
										}
									}
									i{
										position: absolute;
										right: 20px;
										color:#dddddd;
									}
									&[aria-expanded=true]{
										i{
											&:before{
												content:"\e900";
											}
										}
									}
								}
							}
						}
					}
					ul{
						li{
							&:not(:last-child){
								border-bottom: 1px solid #dddddd;
							}
							a{
								font-family: $kelsonlight;
								@include font-size(14,20);
								color:$grey;
								padding: 10px 10px 10px 52px;
								display: block;
								position: relative;
								@include transition;
								&:before{
									content:"\e904";
									font-family: 'icomoon';
									font-size: 9px;
									color:#dddddd;
									position: absolute;
									left: 35px;
									@include transition;
								}
								&:hover,&.active{
									color:$white;
									background: $purple;
									&:before{
										color:$white;
									}
								}
							}
						}
					}
				}
			}
		}
	}
	.product-detail{
		.title{
			background: $white;
			border:1px solid #d9d9d9;
			padding: 15.5px 27px;
			display: table;
			width: 100%;
			@media ($media_max_sm){
				padding: 15.5px 15px;
			}
			h3{
				font-family: $kelsonbold;
				@include font-size(17,24);
				color:$grey;
				display: inline-block;
				float: left;
				text-transform: uppercase;
			}
			span{
				display: inline-block;
				float: left;
				font-family: $kelsonlight;
				@include font-size(17,24);
				color:$grey;
				position: relative;
				padding-left: 14px;
				&:before{
					content:"/";
					position: absolute;
					left: 5px;
				}
			}
		}
		figure{
			margin-top:9px;
			border:1px solid #d9d9d9;
			background: $white;
			height: 292px;
			@include flex;
			align-items: center;
			justify-content: center;
			img{
				max-width: 100%;
				max-height: 100%;
			}
		}
		section{
			background: $white;
			border:1px solid #d9d9d9;
			margin-top: 9px;
			h3{
				font-family: $kelsonbold;
				@include font-size(17,24);
				color:$grey;
				padding: 14px 27px;
				border-bottom: 1px solid #d9d9d9;
				@media ($media_max_sm){
					padding: 14px 15px;
				}
			}
			article{
				padding: 41px 50px;
				font-family: $calibriregular;
				@include font-size(16,24);
				color:$grey;
				@media ($media_max_sm){
					padding: 20px;
				}
				img{
					max-width: 100%;
					height: auto !important;
				}
			}
			ul{
				li{
					&:not(:last-child){
						border-bottom: 1px solid #d9d9d9;
					}
					a{
						font-family: $calibriregular;
						@include font-size(14,24);
						color:$grey;
						display: block;
						padding: 15.5px 27px 15.5px 65px;
						position: relative;
						@include transition;
						&:hover{
							background:#faf6fc;
						}
						@media ($media_max_sm){
							padding: 15.5px 40px;
						}
						i{
							font-size: 16px;
							color:#b345e6;
							position: absolute;
							left: 30px;
							top: 20px;
							@media ($media_max_sm){
								left:15px;
							}
						}
					}
				}
			}
		}
	}
}
/**************************PRODUCTS**************************/

/**************************CONTACT**************************/
.contact{
	max-width: 980px;
	margin:0 auto;
	.contact-information{
		h2{
			font-family: $kelsonregular;
			@include font-size(50,60);
			color:$purple;
			@media ($media_max_sm){
				@include font-size(35,45);
			}
		}
		address{
			font-family: $kelsonlight;
			@include font-size(18,24);
			color:$grey;
			margin:27px 0 16px 0;
		}
		ul{
			li{
				&:not(:last-child){
					margin-bottom: 14px;
				}
				a{
					font-family: $kelsonlight;
					@include font-size(18,24);
					color:$grey;
					position: relative;
					padding-left: 36px;
					i{
						font-size: 22px;
						color:$purple;
						position: absolute;
						left: 0;
					}
				}
			}
		}
	}
	.contact-form{
		max-width: 430px;
		margin:0 auto;
		@media ($media_max_sm){
			margin:30px 0 0 0;
			max-width: 100%;
		}
		div{
			position: relative;
			padding-bottom: 35px;
			label{
				position: absolute;
				left: 0;
				bottom: 0;
				width: 100%;
				font-family: $kelsonregular;
				color:red;
			}
		}
		input,textarea{
			padding: 8px 0;
			font-family: $kelsonlight;
			@include font-size(16,24);
			border-bottom: 1px solid #a2a2a2;
			width: 100%;
			color:$grey;
			background:none;
			@include placeholder{
				color:$grey;
				&:focus{
					color:$purple;
				}
			}
			&:focus{
				border-color:$purple;
			}
		}
		textarea{resize: none;height: 41px}
		button{
			font-family: $kelsonregular;
			@include font-size(20,26);
			color:$white;
			background: $purple;
			text-align: center;
			border-radius: 6px;
			width: 100%;
			padding: 18px 15px;
			margin-top: 30px;
			i{
				font-size: 22px;
				color:$white;
				margin-right: 9px;
				margin-top: -3px;
			}
		}
		p{
			font-family: $kelsonregular;
			@include font-size(16,20);
			margin:15px 0;
			display: none;
			&.success{
				color:green;
				display: block;
			}
			&.error{
				color:red;
				display: block;
			}
		}
	}
}
/**************************CONTACT**************************/

/**************************COOKIE-POLICY**************************/
.cookie-policy{
	background: rgba(109,59,133,0.9);
	padding: 19px 0;
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	z-index: 999;
	display: none;
	&.active{
		display: block;
	}
	.container{
		position: relative;
	}
	p{
		font-family: $kelsonlight;
		@include font-size(13,16);
		width: 70%;
		color:$white;
		@media ($media_max_sm){
			width: 100%;
			padding-right: 50px;
		}
		a{
			color:$white;
			text-decoration: underline !important;
		}
	}
	a.cookie-close{
		display: inline-block;
		padding: 6px;
		background: $white;
		border-radius: 4px;
		position: absolute;
		right: 15px;
		top: 0;
		width: 30px;
		height: 30px;
		text-align: center;
		line-height: 1;
		i{
			font-size: 15px;
			color:$purple;
		}
	}
}
/**************************COOKIE-POLICY**************************/

/**************************404**************************/
.error-404{
	text-align: center;
	padding: 100px 0;
	@media ($media_max_sm){
		padding: 60px 0;
	}
	@media ($media_max_xs){
		padding: 40px 0;
	}
	h1{
		font-family: $kelsonbold;
		@include font-size(180,170);
		color:$purple;
		@media ($media_max_sm){
			@include font-size(140,150);
		}
	}
	h6{
		font-family: $kelsonregular;
		@include font-size(35,45);
		color:$grey;
		@media ($media_max_sm){
			@include font-size(28,32);
		}
	}
	p{
		font-family: $kelsonlight;
		@include font-size(17,24);
		color:$grey;
		margin-top: 6px;
		@media ($media_max_sm){
			@include font-size(16,20);
		}
		a{
			font-family: $kelsonregular;
			color:$purple;
			text-decoration: underline !important;
		}
	}
}
/**************************404**************************/

/**************************STYLEGUIDE**************************/
.styleguide{
	h1{
		font-family: $kelsonbold;
		@include font-size(30,40);
		color:$purple;
		margin-bottom: 10px;
	}
	ul{
		li{
			&:not(:last-child){
				margin-bottom: 10px;
			}
			a{
				font-family: $kelsonregular;
				@include font-size(17,24);
				color:$purple;
				text-decoration: underline !important;
			}
		}
	}
}
/**************************STYLEGUIDE**************************/

