@charset "UTF-8";
html {
  min-height: 100%;
  min-width: 100%; }

body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0 !important;
  min-height: 100%;
  min-width: 100%;
  background: #ffffff; }

*::selection {
  background: #ffffff;
  color: #000000; }

/*************************reset-erd*************************/
h1, h2, h3, h4, h5, h6, th, p, label, figure {
  font-weight: normal;
  margin: 0; }

a {
  text-decoration: none !important; }

ul {
  list-style: none;
  padding: 0;
  margin: 0; }

input, select, textarea, button, a {
  outline: none !important;
  border: none; }

select {
  -webkit-appearance: none; }

input, select, textarea {
  background: #ffffff;
  border-radius: 0; }

i {
  display: inline-block;
  vertical-align: middle; }

nav {
  background: none;
  border: none;
  margin: 0;
  min-height: auto !important; }

nav button.navbar-toggle {
  background: none !important;
  border: none !important; }

.navbar-collapse {
  border: none !important;
  box-shadow: 0 0 0 0 !important;
  padding: 0; }

.navbar-brand {
  padding: 0;
  height: auto; }

.dropdown button {
  box-shadow: 0 0 0 0 !important;
  border: none;
  padding: 0; }

/*************************reset-erd*************************/
@font-face {
  font-family: 'kelson_sansbold';
  src: url("../fonts/kelsonsans-bold-webfont.eot");
  src: url("../fonts/kelsonsans-bold-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/kelsonsans-bold-webfont.woff2") format("woff2"), url("../fonts/kelsonsans-bold-webfont.woff") format("woff"), url("../fonts/kelsonsans-bold-webfont.ttf") format("truetype"), url("../fonts/kelsonsans-bold-webfont.svg#kelson_sansbold") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'kelson_sansnormal';
  src: url("../fonts/kelsonsans-normal-webfont.eot");
  src: url("../fonts/kelsonsans-normal-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/kelsonsans-normal-webfont.woff2") format("woff2"), url("../fonts/kelsonsans-normal-webfont.woff") format("woff"), url("../fonts/kelsonsans-normal-webfont.ttf") format("truetype"), url("../fonts/kelsonsans-normal-webfont.svg#kelson_sansnormal") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'kelson_sanslight';
  src: url("../fonts/kelsonsans-light-webfont.eot");
  src: url("../fonts/kelsonsans-light-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/kelsonsans-light-webfont.woff2") format("woff2"), url("../fonts/kelsonsans-light-webfont.woff") format("woff"), url("../fonts/kelsonsans-light-webfont.ttf") format("truetype"), url("../fonts/kelsonsans-light-webfont.svg#kelson_sanslight") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Conv_Calibri';
  src: url("../fonts/Calibri.eot");
  src: local("☺"), url("../fonts/Calibri.woff") format("woff"), url("../fonts/Calibri.ttf") format("truetype"), url("../fonts/Calibri.svg") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon.eot?sy4dhr");
  src: url("../fonts/icomoon.eot?sy4dhr#iefix") format("embedded-opentype"), url("../fonts/icomoon.ttf?sy4dhr") format("truetype"), url("../fonts/icomoon.woff?sy4dhr") format("woff"), url("../fonts/icomoon.svg?sy4dhr#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

/*---------------------Navbar--------------------------*/
@media (max-width: 991px) {
  header {
    height: auto;
    min-width: 310px; }
  .navbar-header {
    float: none; }
  .navbar-toggle {
    display: block; }
  .navbar-collapse {
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
    max-width: 100% !important; }
    .navbar-collapse.collapse {
      display: none !important;
      overflow-y: auto !important; }
  .navbar-nav {
    float: none !important;
    margin: 0; }
    .navbar-nav > li {
      float: none; }
      .navbar-nav > li > a {
        padding-top: 10px;
        padding-bottom: 10px; }
  .navbar-text {
    float: none;
    margin: 15px 0; }
  .navbar-collapse.collapse.in {
    display: block !important; }
  .collapsing {
    overflow: hidden !important; } }

/*---------------------Navbar--------------------------*/
/*---------------------Carosuel-fade--------------------------*/
.carousel-fade .carousel-inner .item {
  transition-property: opacity; }

.carousel-fade .carousel-inner .item,
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  opacity: 0; }

.carousel-fade .carousel-inner .active,
.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
  opacity: 1; }

.carousel-fade .carousel-inner .next,
.carousel-fade .carousel-inner .prev,
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  left: 0;
  transform: translate3d(0, 0, 0); }

.carousel-fade .carousel-control {
  z-index: 2; }

/*---------------------Carosuel-fade--------------------------*/
/*---------------------Transition--------------------------*/
/*---------------------Transition--------------------------*/
.navbar-inverse {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0; }
  .navbar-inverse button.navbar-toggle {
    margin: 0; }
  .navbar-inverse li.active a {
    background: none !important; }

[class^="icon-"], [class*=" icon-"] {
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-shield:before {
  content: "\e917"; }

.icon-minus:before {
  content: "\e900"; }

.icon-plus:before {
  content: "\e916"; }

.icon-play:before {
  content: "\e901"; }

.icon-bottom:before {
  content: "\e902"; }

.icon-top:before {
  content: "\e903"; }

.icon-next:before {
  content: "\e904"; }

.icon-prev:before {
  content: "\e905"; }

.icon-close:before {
  content: "\e906"; }

.icon-pdf:before {
  content: "\e907"; }

.icon-word:before {
  content: "\e908"; }

.icon-link:before {
  content: "\e909"; }

.icon-location:before {
  content: "\e90a"; }

.icon-fax:before {
  content: "\e90b"; }

.icon-direction:before {
  content: "\e90c"; }

.icon-back:before {
  content: "\e90d"; }

.icon-instagram:before {
  content: "\e90e"; }

.icon-facebook:before {
  content: "\e90f"; }

.icon-twitter:before {
  content: "\e910"; }

.icon-send:before {
  content: "\e911"; }

.icon-comment:before {
  content: "\e912"; }

.icon-mail:before {
  content: "\e913"; }

.icon-phone-stroke:before {
  content: "\e914"; }

.icon-calendar:before {
  content: "\e915"; }

.icon-phone:before {
  content: "\e92d"; }

.container {
  max-width: 1200px;
  width: 100%; }

body {
  background: url(../images/content/noise.png) repeat; }

/**************************HEADER**************************/
header {
  position: absolute;
  top: 40px;
  z-index: 2;
  width: 100%; }
  @media (max-width: 991px) {
    header {
      top: 0;
      position: fixed;
      z-index: 999; } }
  @media (max-width: 991px) {
    header .container {
      padding: 0; } }
  header nav.navbar-inverse {
    background: #6d3b85;
    padding: 19px 27px; }
    @media (max-width: 991px) {
      header nav.navbar-inverse {
        padding: 19px 15px; } }
    header nav.navbar-inverse .navbar-header {
      margin-top: 10px; }
      @media (max-width: 991px) {
        header nav.navbar-inverse .navbar-header {
          margin: 0; } }
      header nav.navbar-inverse .navbar-header img {
        max-width: 260px;
        width: 100%; }
        @media (max-width: 991px) {
          header nav.navbar-inverse .navbar-header img {
            max-width: 240px; } }
      header nav.navbar-inverse .navbar-header button {
        padding: 0; }
        header nav.navbar-inverse .navbar-header button span {
          height: 2px;
          width: 30px; }
          header nav.navbar-inverse .navbar-header button span + span {
            margin-top: 10px !important; }
    header nav.navbar-inverse .navbar-collapse ul:not(.navbar-right) {
      margin-top: 12px;
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      margin-left: 30px; }
      @media (max-width: 991px) {
        header nav.navbar-inverse .navbar-collapse ul:not(.navbar-right) {
          position: initial;
          transform: translate(0);
          left: 0;
          margin-left: 0;
          margin-bottom: 10px;
          border-bottom: 1px solid #5f2b77; } }
      header nav.navbar-inverse .navbar-collapse ul:not(.navbar-right) li {
        padding: 0 16px; }
        @media (max-width: 991px) {
          header nav.navbar-inverse .navbar-collapse ul:not(.navbar-right) li {
            padding: 5px 0;
            text-align: center; } }
        header nav.navbar-inverse .navbar-collapse ul:not(.navbar-right) li a {
          font-family: "kelson_sansnormal";
          font-size: 1.7rem;
          line-height: 2.4rem;
          padding: 0;
          color: #ffffff;
          text-transform: uppercase; }
        header nav.navbar-inverse .navbar-collapse ul:not(.navbar-right) li.active a {
          border-bottom: 2px solid #ffffff; }
    header nav.navbar-inverse .navbar-collapse .navbar-right {
      margin: 0; }
      @media (max-width: 991px) {
        header nav.navbar-inverse .navbar-collapse .navbar-right {
          margin: 0 auto;
          width: auto;
          display: table; } }
      @media (max-width: 991px) {
        header nav.navbar-inverse .navbar-collapse .navbar-right li {
          float: left; } }
      header nav.navbar-inverse .navbar-collapse .navbar-right li a {
        font-family: "kelson_sansnormal";
        font-size: 1.5rem;
        line-height: 2.4rem;
        color: #ffffff;
        border: 2px solid #ffffff;
        padding: 10px;
        height: 48px; }
        header nav.navbar-inverse .navbar-collapse .navbar-right li a i {
          font-size: 24px; }
          header nav.navbar-inverse .navbar-collapse .navbar-right li a i.icon-mail {
            font-size: 21px;
            margin-right: 6px;
            margin-top: -3px; }
            @media (max-width: 991px) {
              header nav.navbar-inverse .navbar-collapse .navbar-right li a i.icon-mail {
                margin: 0; } }
      header nav.navbar-inverse .navbar-collapse .navbar-right li:not(:first-child) {
        padding-left: 8px; }
        header nav.navbar-inverse .navbar-collapse .navbar-right li:not(:first-child) a {
          padding: 10px 14px; }
          @media (max-width: 991px) {
            header nav.navbar-inverse .navbar-collapse .navbar-right li:not(:first-child) a span {
              display: none; } }
      header nav.navbar-inverse .navbar-collapse .navbar-right li.lang i {
        display: none; }
        @media (max-width: 991px) {
          header nav.navbar-inverse .navbar-collapse .navbar-right li.lang i {
            display: inline-block; } }

/**************************HEADER**************************/
/**************************PAGE-TOP**************************/
.page-top {
  position: relative;
  background: #fcf9fd;
  background: linear-gradient(180deg, #fcf9fd 68%, #f7eefa 100%); }
  .page-top.home {
    padding-top: 126px; }
    @media (max-width: 991px) {
      .page-top.home {
        padding-top: 100px; } }
    .page-top.home .shield-container {
      text-align: right;
      padding-top: 10px;
      position: absolute;
      width: 100%;
      bottom: 0; }
      @media (max-width: 767px) {
        .page-top.home .shield-container {
          bottom: auto;
          top: 70px; } }
      .page-top.home .shield-container p.shield {
        padding-bottom: 15px;
        font-family: "kelson_sansbold";
        font-size: 1.8rem;
        line-height: 2.2rem;
        color: #6d3b85; }
        .page-top.home .shield-container p.shield i {
          padding-right: 10px;
          font-size: 30px; }
  .page-top .page-banner {
    background-size: cover !important;
    background-position: center !important;
    height: 276px;
    padding-top: 224px; }
    @media (max-width: 991px) {
      .page-top .page-banner {
        height: 176px;
        padding-top: 128px; } }
    .page-top .page-banner h2 {
      font-family: "kelson_sansbold";
      font-size: 3rem;
      line-height: 3.6rem;
      color: #ffffff;
      text-transform: uppercase;
      float: left; }
      @media (max-width: 767px) {
        .page-top .page-banner h2 {
          font-size: 2.4rem;
          line-height: 3rem;
          float: none;
          position: absolute;
          left: 15px;
          bottom: 15px; } }
    .page-top .page-banner a.back {
      font-family: "kelson_sanslight";
      font-size: 1.3rem;
      line-height: 1.6rem;
      color: #ffffff;
      display: inline-block;
      background: #6d3b85;
      float: right;
      padding: 7px 15px; }
      .page-top .page-banner a.back i {
        font-size: 17px;
        color: #ffffff;
        margin-right: 5px;
        margin-top: -3px; }
      @media (max-width: 767px) {
        .page-top .page-banner a.back {
          display: none; } }
    .page-top .page-banner.map {
      height: 495px; }
      .page-top .page-banner.map #map {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1; }

/**************************PAGE-TOP**************************/
/**************************CAROUSEL**************************/
.carousel {
  padding: 93px 0 0 0;
  height: 528px; }
  @media (max-width: 991px) {
    .carousel {
      height: auto;
      padding: 0 0 0 0; } }
  .carousel .carousel-inner {
    padding-bottom: 50px; }
  .carousel ol.carousel-indicators {
    bottom: 20px;
    left: 0;
    width: 100%;
    text-align: center;
    margin: 0; }
    .carousel ol.carousel-indicators li {
      border-color: #6d3b85;
      height: 7px;
      width: 30px;
      border-radius: 5px;
      margin: 0 2px !important; }
      .carousel ol.carousel-indicators li.active {
        background: #6d3b85; }
      @media (max-width: 767px) {
        .carousel ol.carousel-indicators li {
          width: 20px; } }
  .carousel .slide-title {
    padding-top: 50px; }
    @media (max-width: 991px) {
      .carousel .slide-title {
        padding-top: 30px;
        text-align: center; } }
    .carousel .slide-title h1 {
      font-family: "kelson_sansbold";
      font-size: 4.5rem;
      line-height: 5rem;
      color: #4b4b4b;
      margin-bottom: 10px;
      opacity: 0; }
      @media (max-width: 991px) {
        .carousel .slide-title h1 {
          font-size: 3rem;
          line-height: 3.5rem; } }
      .carousel .slide-title h1 span {
        display: block; }
    .carousel .slide-title p {
      font-family: "kelson_sanslight";
      font-size: 2rem;
      line-height: 2.4rem;
      color: #4b4b4b;
      opacity: 0; }
    .carousel .slide-title a {
      font-family: "kelson_sansbold";
      font-size: 1.3rem;
      line-height: 1.6rem;
      color: #6d3b85;
      display: inline-block;
      border: 2px solid #6d3b85;
      padding: 11px 15px;
      margin-top: 30px;
      opacity: 0; }
      .carousel .slide-title a i {
        font-size: 21px;
        margin-right: 13px;
        margin-top: -3px; }
  .carousel .slide-image figure {
    text-align: right;
    opacity: 0; }
    @media (max-width: 991px) {
      .carousel .slide-image figure {
        height: 200px;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        align-items: center;
        justify-content: center; } }
    .carousel .slide-image figure img {
      max-width: 100%;
      max-height: 378px; }
      @media (max-width: 991px) {
        .carousel .slide-image figure img {
          max-height: 200px; } }
  .carousel .item.active h1 {
    animation-name: fadeInRight; }
  .carousel .item.active p {
    animation-name: fadeInLeft; }
  .carousel .item.active a {
    animation-name: fadeInUp; }
  .carousel .item.active .slide-image figure {
    animation-name: fadeIn; }

/**************************CAROUSEL**************************/
/**************************MAIN-CORPORATE**************************/
.main-corporate {
  padding: 50px 0; }
  .main-corporate h2 {
    font-family: "kelson_sansbold";
    font-size: 2.6rem;
    line-height: 3.2rem;
    color: #4b4b4b;
    position: relative;
    margin-bottom: 34px;
    text-transform: uppercase; }
    @media (max-width: 767px) {
      .main-corporate h2 {
        font-size: 2.2rem;
        line-height: 2.5rem; } }
    .main-corporate h2:before {
      content: "";
      position: absolute;
      left: 0;
      bottom: -7px;
      max-width: 100px;
      width: 100%;
      height: 3px;
      background: #6d3b85;
      background: linear-gradient(90deg, #6d3b85 50%, rgba(247, 238, 250, 0) 100%); }
  .main-corporate .corporate {
    height: 347px;
    background: #ffffff url(../images/content/main-corporate.jpg) no-repeat center left;
    background-size: auto 100%;
    padding: 34px 15px 34px 284px; }
    @media (max-width: 1200px) {
      .main-corporate .corporate {
        padding-left: 200px; } }
    @media (max-width: 991px) {
      .main-corporate .corporate {
        height: auto;
        background-position: center left -130px;
        padding-left: 15px;
        margin-bottom: 15px; } }
    .main-corporate .corporate p {
      font-family: "kelson_sanslight";
      font-size: 1.6rem;
      line-height: 2.4rem;
      color: #4b4b4b;
      margin-bottom: 60px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 5;
      overflow: hidden; }
      @media (max-width: 991px) {
        .main-corporate .corporate p {
          height: auto; } }
    .main-corporate .corporate a {
      font-family: "kelson_sansbold";
      font-size: 1.3rem;
      line-height: 1.7rem;
      color: #6d3b85; }
      .main-corporate .corporate a i {
        font-size: 8px;
        color: #6d3b85;
        padding: 9.5px 8px 9.5px 11px;
        border: 2px solid #6d3b85;
        margin-right: 9px; }
  .main-corporate .announcements {
    background: #ffffff;
    position: relative; }
    .main-corporate .announcements .top {
      padding: 34px 30px 25px 44px;
      position: absolute;
      top: 0;
      z-index: 2; }
      @media (max-width: 991px) {
        .main-corporate .announcements .top {
          padding-left: 15px; } }
      .main-corporate .announcements .top h2 {
        margin: 0; }
    .main-corporate .announcements .swiper-container {
      width: 100%;
      height: 347px;
      padding-top: 92px; }
      .main-corporate .announcements .swiper-container:before {
        content: "";
        position: absolute;
        left: 0;
        width: 100%;
        height: 93px;
        background: #ffffff;
        top: 0;
        z-index: 3;
        border-bottom: 1px solid #f1f1f1; }
    .main-corporate .announcements .wrap {
      padding: 23px 20px 24px 44px;
      border-bottom: 1px solid #f1f1f1; }
      @media (max-width: 991px) {
        .main-corporate .announcements .wrap {
          padding-left: 15px; } }
      .main-corporate .announcements .wrap p {
        font-family: "kelson_sanslight";
        font-size: 1.6rem;
        line-height: 2rem;
        color: #000000;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        height: 40px; }
        .main-corporate .announcements .wrap p:first-child {
          height: auto;
          margin-bottom: 19px; }
        .main-corporate .announcements .wrap p i {
          margin-right: 9px;
          color: #6d3b85;
          font-size: 19px; }

.swiper-container .controls ul {
  display: table;
  position: absolute;
  right: 28px;
  top: 36px;
  z-index: 3; }
  @media (max-width: 767px) {
    .swiper-container .controls ul {
      top: 32px;
      right: 10px; } }
  .swiper-container .controls ul li {
    float: left;
    display: inline-block;
    cursor: pointer;
    background: none;
    position: initial;
    border: 2px solid #6d3b85;
    -webkit-transition: .3s ease-in-out;
    -moz-transition: .3s ease-in-out;
    -ms-transition: .3s ease-in-out;
    -o-transition: .3s ease-in-out;
    transition: .3s ease-in-out; }
    .swiper-container .controls ul li:not(:last-child) {
      width: 30px;
      height: 30px;
      text-align: center;
      border-color: #d6d6d6;
      line-height: 1.8;
      margin: 0 2.5px; }
    .swiper-container .controls ul li:last-child {
      margin-left: 12px; }
      @media (max-width: 991px) {
        .swiper-container .controls ul li:last-child {
          width: 30px;
          height: 30px;
          margin-left: 2.5px; } }
      .swiper-container .controls ul li:last-child i {
        display: none;
        font-size: 13px;
        color: #6d3b85; }
        @media (max-width: 991px) {
          .swiper-container .controls ul li:last-child i {
            display: block; } }
      @media (max-width: 991px) {
        .swiper-container .controls ul li:last-child span {
          display: none; } }
    .swiper-container .controls ul li i {
      font-size: 10px;
      color: #d6d6d6; }
    .swiper-container .controls ul li a {
      font-family: "kelson_sansbold";
      font-size: 1.3rem;
      line-height: 1.6rem;
      color: #6d3b85;
      padding: 5px 11px;
      display: block; }
      @media (max-width: 991px) {
        .swiper-container .controls ul li a {
          padding: 5px 7px; } }

/**************************MAIN-CORPORATE**************************/
/**************************MAIN-PRODUCT**************************/
.main-product-group {
  padding: 81px 0 89px 0;
  background: url(../images/content/main-product-group-bg.jpg) no-repeat center;
  background-size: cover; }
  .main-product-group h2 {
    font-family: "kelson_sansbold";
    font-size: 2.7rem;
    line-height: 3.2rem;
    color: #ffffff;
    position: absolute;
    top: 4px; }
    @media (max-width: 767px) {
      .main-product-group h2 {
        font-size: 2.2rem;
        line-height: 2.5rem; } }
  .main-product-group .wrap {
    padding: 30px 30px 22px 33px;
    background: #ffffff;
    position: relative; }
    .main-product-group .wrap figure {
      height: 210px;
      text-align: center;
      margin-bottom: 22px; }
      .main-product-group .wrap figure img {
        max-height: 100%; }
    .main-product-group .wrap .title {
      height: 48px;
      display: flex;
      align-items: center; }
    .main-product-group .wrap h3 {
      font-family: "kelson_sansbold";
      font-size: 2rem;
      line-height: 2.4rem;
      color: #4b4b4b;
      margin-bottom: 4px;
      padding-right: 60px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      text-transform: uppercase; }
    .main-product-group .wrap i.icon-plus {
      font-size: 22px;
      color: #6d3b85;
      padding: 11px;
      border: 2px solid #6d3b85;
      position: absolute;
      bottom: 22px;
      right: 30px;
      -webkit-transition: .3s ease-in-out;
      -moz-transition: .3s ease-in-out;
      -ms-transition: .3s ease-in-out;
      -o-transition: .3s ease-in-out;
      transition: .3s ease-in-out; }
    .main-product-group .wrap:hover i.icon-plus {
      color: #ffffff;
      background: #6d3b85; }
  .main-product-group .swiper-container {
    padding-top: 61px; }
    .main-product-group .swiper-container .controls ul {
      right: 0;
      top: 0; }
      .main-product-group .swiper-container .controls ul li {
        border: 2px solid #ffffff; }
        .main-product-group .swiper-container .controls ul li:not(:last-child) {
          border-color: #ffffff; }
        .main-product-group .swiper-container .controls ul li:last-child i {
          color: #ffffff; }
        .main-product-group .swiper-container .controls ul li i {
          color: #ffffff; }
        .main-product-group .swiper-container .controls ul li a {
          color: #ffffff; }

/**************************MAIN-PRODUCT**************************/
/**************************MAIN-REPRESENTATIONS**************************/
.main-representations {
  padding: 61px 0 55px 0; }
  .main-representations h2 {
    font-family: "kelson_sansbold";
    font-size: 2.7rem;
    line-height: 3.2rem;
    color: #4b4b4b;
    position: absolute;
    top: 4px;
    text-transform: uppercase; }
    @media (max-width: 767px) {
      .main-representations h2 {
        font-size: 2.2rem;
        line-height: 2.5rem; } }
  .main-representations figure {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 130px;
    padding: 0 10px; }
    .main-representations figure img {
      max-width: 100%; }
  .main-representations .swiper-container {
    padding-top: 61px; }
    .main-representations .swiper-container:before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 178px;
      background: #ffffff; }
    .main-representations .swiper-container .swiper-wrapper {
      padding: 28px 0 20px 0; }
    .main-representations .swiper-container .controls ul {
      top: 0;
      right: 0; }
      .main-representations .swiper-container .controls ul li {
        border: 2px solid #6d3b85; }
        .main-representations .swiper-container .controls ul li:not(:last-child) {
          border-color: #d6d6d6; }
        .main-representations .swiper-container .controls ul li:last-child i {
          color: #6d3b85; }
        .main-representations .swiper-container .controls ul li i {
          color: #d6d6d6; }

/**************************MAIN-REPRESENTATIONS**************************/
/**************************FOOTER**************************/
footer {
  background: #ffffff;
  padding: 40px 0 0 0; }
  @media (max-width: 991px) {
    footer {
      padding-top: 10px; } }
  footer > .container > .row > div {
    padding: 0 15px;
    float: left; }
    footer > .container > .row > div.col-erd-2 {
      width: 14%; }
    footer > .container > .row > div.col-erd-2-5 {
      width: 19.5%; }
    footer > .container > .row > div.col-erd-3 {
      width: 22.25%; }
    footer > .container > .row > div.col-erd-3-5 {
      width: 22%; }
    @media (max-width: 991px) {
      footer > .container > .row > div {
        width: 100% !important; } }
    footer > .container > .row > div > div {
      max-width: 300px; }
      @media (max-width: 991px) {
        footer > .container > .row > div > div {
          margin: 15px auto;
          text-align: center; } }
  footer h4 {
    font-family: "kelson_sansbold";
    font-size: 1.8rem;
    line-height: 2.4rem;
    color: #6d3b85;
    margin-bottom: 20px;
    text-transform: uppercase; }
  footer .footer-menu ul li {
    font-family: "kelson_sansnormal";
    font-size: 1.4rem;
    line-height: 1.8rem; }
    footer .footer-menu ul li:not(:last-child) {
      margin-bottom: 22px; }
      @media (max-width: 991px) {
        footer .footer-menu ul li:not(:last-child) {
          margin-bottom: 15px; } }
    footer .footer-menu ul li a {
      font-family: "kelson_sansnormal";
      font-size: 1.4rem;
      line-height: 1.8rem;
      color: #4b4b4b; }
  @media (max-width: 991px) {
    footer .e-bulten {
      margin: 0 auto; } }
  footer .e-bulten p {
    font-family: "kelson_sansnormal";
    font-size: 1.4rem;
    line-height: 2rem;
    color: #4b4b4b;
    margin-bottom: 35px; }
  footer .e-bulten .wrap {
    position: relative; }
    footer .e-bulten .wrap input {
      padding: 6px 0;
      font-family: "kelson_sansnormal";
      font-size: 1.4rem;
      line-height: 2rem;
      border-bottom: 2px solid #6d3b85;
      width: 100%;
      color: #4b4b4b;
      background: none;
      padding-right: 35px; }
      footer .e-bulten .wrap input::-webkit-input-placeholder {
        color: #d6d6d6; }
      footer .e-bulten .wrap input:-moz-placeholder {
        color: #d6d6d6; }
      footer .e-bulten .wrap input::-moz-placeholder {
        color: #d6d6d6; }
      footer .e-bulten .wrap input:-ms-input-placeholder {
        color: #d6d6d6; }
    footer .e-bulten .wrap button {
      background: #6d3b85;
      padding: 5px;
      position: absolute;
      right: 0;
      bottom: 6px;
      width: 26px;
      height: 26px; }
      footer .e-bulten .wrap button i {
        font-size: 14px;
        color: #ffffff;
        margin-top: -7px; }
  footer .contact address {
    margin-top: -11px;
    font-family: "kelson_sansnormal";
    font-size: 1.4rem;
    line-height: 4rem;
    color: #4b4b4b;
    margin-bottom: 17px; }
  footer .contact ul li:not(:last-child) {
    margin-bottom: 14px; }
  footer .contact ul li a {
    font-family: "kelson_sansnormal";
    font-size: 1.4rem;
    line-height: 1.8rem;
    color: #4b4b4b;
    padding-left: 35px;
    position: relative; }
    footer .contact ul li a i {
      position: absolute;
      left: 0;
      font-size: 24px;
      color: #6d3b85; }
  footer .bottom-bar {
    background: #5f2b77;
    margin-top: 46px; }
    @media (max-width: 991px) {
      footer .bottom-bar .row > div {
        padding: 0; } }
    footer .bottom-bar p {
      font-family: "kelson_sansnormal";
      font-size: 1.3rem;
      line-height: 1.6rem;
      color: #ffffff;
      padding-top: 19px; }
      @media (max-width: 991px) {
        footer .bottom-bar p {
          padding: 19px 0;
          text-align: center; } }
    footer .bottom-bar a.comments {
      padding: 19px 15px;
      font-family: "kelson_sansnormal";
      font-size: 1.3rem;
      line-height: 1.6rem;
      color: #ffffff;
      background: #6d3b85;
      display: block;
      text-align: center; }
      footer .bottom-bar a.comments i {
        font-size: 21px;
        color: #ffffff;
        margin-right: 9px;
        margin-top: -2px; }
    footer .bottom-bar ul {
      display: table;
      padding: 13px 0;
      margin: 0 auto; }
      footer .bottom-bar ul li {
        float: left;
        display: inline-block; }
        footer .bottom-bar ul li:not(:last-child) {
          padding-right: 25px; }
        footer .bottom-bar ul li a i {
          font-size: 20px;
          color: #ffffff; }
        footer .bottom-bar ul li a img {
          height: 30px; }

/**************************FOOTER**************************/
.wrapper {
  padding: 87px 0; }
  @media (max-width: 991px) {
    .wrapper {
      padding: 40px 0; } }

/**************************CORPORATE**************************/
.corporate h5 {
  font-family: "kelson_sansbold";
  font-size: 3rem;
  line-height: 3.6rem;
  color: #6d3b85;
  margin-bottom: 25px;
  text-transform: uppercase; }
  @media (max-width: 767px) {
    .corporate h5 {
      font-size: 2.4rem;
      line-height: 3rem; } }

.corporate p {
  font-family: "kelson_sansnormal";
  font-size: 1.5rem;
  line-height: 2.8rem;
  color: #4b4b4b; }

/**************************CORPORATE**************************/
/**************************REPRESENTATIONS**************************/
.representations .wrap {
  background: #ffffff;
  margin-bottom: 30px; }
  .representations .wrap figure {
    height: 210px;
    border-bottom: 1px solid #f0f0f0;
    padding: 40px 15px 25px 15px;
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center; }
    .representations .wrap figure:before {
      content: "";
      position: absolute;
      left: 0;
      bottom: -1px;
      width: 40%;
      background: #6d3b85;
      height: 1px; }
    .representations .wrap figure img {
      max-height: 100%;
      max-width: 100%; }
  .representations .wrap section {
    padding: 30px 20px 25px 20px;
    text-align: center;
    min-height: 352px; }
    .representations .wrap section h6 {
      font-family: "kelson_sansbold";
      font-size: 2.2rem;
      line-height: 2.6rem;
      color: #2c2c2c;
      margin-bottom: 16px; }
    .representations .wrap section p {
      font-family: "kelson_sanslight";
      font-size: 1.5rem;
      line-height: 2.4rem;
      color: #2c2c2c;
      margin-bottom: 35px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 7;
      overflow: hidden; }
      @media (max-width: 767px) {
        .representations .wrap section p {
          -webkit-line-clamp: inherit; } }
    .representations .wrap section a {
      display: block;
      max-width: 260px;
      font-family: "kelson_sanslight";
      font-size: 1.6rem;
      line-height: 2.2rem;
      color: #2c2c2c;
      padding: 14px;
      text-align: center;
      margin: 0 auto;
      border-radius: 6px;
      border: 1px solid #e7e7e7;
      -webkit-transition: .3s ease-in-out;
      -moz-transition: .3s ease-in-out;
      -ms-transition: .3s ease-in-out;
      -o-transition: .3s ease-in-out;
      transition: .3s ease-in-out; }
      .representations .wrap section a:hover {
        border-color: #6d3b85; }
      .representations .wrap section a i {
        font-size: 22px;
        color: #6d3b85;
        margin-right: 5px;
        margin-top: -3px; }

/**************************REPRESENTATIONS**************************/
/**************************ANNOUNCEMENTS**************************/
.announcements .panel {
  border-radius: 0; }
  .announcements .panel + .panel {
    margin-top: 15px; }
  .announcements .panel .panel-heading {
    background: none;
    border-radius: 0;
    padding: 0; }
    .announcements .panel .panel-heading a {
      font-family: "kelson_sanslight";
      font-size: 1.5rem;
      line-height: 2.4rem;
      color: #4b4b4b;
      display: block;
      position: relative;
      padding: 23px;
      padding-left: 216px;
      padding-right: 100px; }
      @media (max-width: 767px) {
        .announcements .panel .panel-heading a {
          padding: 15px; } }
      .announcements .panel .panel-heading a span {
        position: absolute; }
        .announcements .panel .panel-heading a span.calendar {
          display: inline-block;
          min-width: 184px;
          padding: 23px 23px 23px 37px;
          left: 0;
          top: 0;
          height: 100%;
          border-right: 1px solid #efefef; }
          @media (max-width: 767px) {
            .announcements .panel .panel-heading a span.calendar {
              position: initial;
              width: 100%;
              display: block;
              border-right: 0;
              padding: 0 0 15px 0;
              border-bottom: 1px solid #efefef;
              margin-bottom: 15px; } }
          .announcements .panel .panel-heading a span.calendar i {
            font-size: 19px;
            color: #6d3b85;
            margin-right: 10px;
            margin-top: -3px; }
        .announcements .panel .panel-heading a span.plus-minus {
          width: 75px;
          border-left: 1px solid #efefef;
          right: 0;
          top: 0;
          height: 100%;
          display: -webkit-box;
          display: -moz-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          align-items: center;
          justify-content: center; }
          @media (max-width: 767px) {
            .announcements .panel .panel-heading a span.plus-minus {
              height: 55px; } }
          .announcements .panel .panel-heading a span.plus-minus i {
            color: #efefef;
            font-size: 22px; }
  .announcements .panel .panel-body {
    padding: 44px 37px;
    font-family: "kelson_sanslight";
    font-size: 1.5rem;
    line-height: 2.4rem;
    color: #4b4b4b; }
    @media (max-width: 767px) {
      .announcements .panel .panel-body {
        padding: 20px 15px; } }
  .announcements .panel.active .panel-heading span.plus-minus i:before {
    content: "\e900"; }

/**************************ANNOUNCEMENTS**************************/
/**************************PRODUCTS**************************/
.products .products-menu .mobile-menu-title {
  display: none;
  margin-bottom: 15px;
  cursor: pointer; }
  @media (max-width: 991px) {
    .products .products-menu .mobile-menu-title {
      display: block; } }
  .products .products-menu .mobile-menu-title h3 {
    font-family: "kelson_sansnormal";
    font-size: 1.5rem;
    line-height: 2.4rem;
    color: #ffffff;
    padding: 17px 20px;
    border: 1px solid #6d3b85;
    background: #6d3b85;
    position: relative;
    padding-right: 45px; }
    .products .products-menu .mobile-menu-title h3:before {
      content: "\e902";
      font-family: 'icomoon';
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translate(0, -50%);
      color: #ffffff;
      font-size: 14px; }

.products .products-menu .panel-group {
  margin: 0; }
  .products .products-menu .panel-group .panel-heading {
    background: none;
    border: none;
    border-radius: 0;
    padding: 0; }

.products .products-menu .main-panel-group {
  display: block; }
  @media (max-width: 991px) {
    .products .products-menu .main-panel-group {
      display: none;
      margin-bottom: 15px; }
      .products .products-menu .main-panel-group.active {
        display: block; } }
  .products .products-menu .main-panel-group > .panel-default {
    border-radius: 0;
    box-shadow: 0 0 0 0; }
    .products .products-menu .main-panel-group > .panel-default + .panel {
      margin-top: 9px; }
    .products .products-menu .main-panel-group > .panel-default > .panel-heading a {
      display: block;
      font-family: "kelson_sansnormal";
      font-size: 1.5rem;
      line-height: 2.4rem;
      color: #4b4b4b;
      background: #ffffff;
      padding: 16px 35px 16px 20px;
      position: relative;
      text-transform: uppercase; }
      .products .products-menu .main-panel-group > .panel-default > .panel-heading a:before {
        content: "\e916";
        font-family: 'icomoon';
        position: absolute;
        right: 20px;
        color: #dddddd;
        top: 50%;
        transform: translate(0, -50%); }
      .products .products-menu .main-panel-group > .panel-default > .panel-heading a[aria-expanded=true]:before {
        content: "\e900"; }
    .products .products-menu .main-panel-group > .panel-default .panel-body {
      padding: 0; }
      .products .products-menu .main-panel-group > .panel-default .panel-body > .panel-group .panel-default {
        border: none;
        margin: 0 !important; }
        .products .products-menu .main-panel-group > .panel-default .panel-body > .panel-group .panel-default:not(:last-child) {
          border-bottom: 1px solid #dddddd; }
        .products .products-menu .main-panel-group > .panel-default .panel-body > .panel-group .panel-default .panel-heading a {
          display: block;
          font-family: "kelson_sanslight";
          font-size: 1.4rem;
          line-height: 2rem;
          color: #4b4b4b;
          padding: 13.5px 15px 13.5px 43px;
          position: relative;
          -webkit-transition: .3s ease-in-out;
          -moz-transition: .3s ease-in-out;
          -ms-transition: .3s ease-in-out;
          -o-transition: .3s ease-in-out;
          transition: .3s ease-in-out; }
          .products .products-menu .main-panel-group > .panel-default .panel-body > .panel-group .panel-default .panel-heading a:before {
            content: "\e901";
            font-family: 'icomoon';
            font-size: 6px;
            color: #dddddd;
            position: absolute;
            left: 26px;
            top: 50%;
            transform: translate(0, -50%);
            -webkit-transition: .3s ease-in-out;
            -moz-transition: .3s ease-in-out;
            -ms-transition: .3s ease-in-out;
            -o-transition: .3s ease-in-out;
            transition: .3s ease-in-out; }
          .products .products-menu .main-panel-group > .panel-default .panel-body > .panel-group .panel-default .panel-heading a:after {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 2px;
            height: 0;
            background: #6d3b85;
            -webkit-transition: .3s ease-in-out;
            -moz-transition: .3s ease-in-out;
            -ms-transition: .3s ease-in-out;
            -o-transition: .3s ease-in-out;
            transition: .3s ease-in-out; }
          .products .products-menu .main-panel-group > .panel-default .panel-body > .panel-group .panel-default .panel-heading a:hover, .products .products-menu .main-panel-group > .panel-default .panel-body > .panel-group .panel-default .panel-heading a.active {
            background: #faf6fc; }
            .products .products-menu .main-panel-group > .panel-default .panel-body > .panel-group .panel-default .panel-heading a:hover:before, .products .products-menu .main-panel-group > .panel-default .panel-body > .panel-group .panel-default .panel-heading a.active:before {
              color: #6d3b85; }
            .products .products-menu .main-panel-group > .panel-default .panel-body > .panel-group .panel-default .panel-heading a:hover:after, .products .products-menu .main-panel-group > .panel-default .panel-body > .panel-group .panel-default .panel-heading a.active:after {
              height: 100%; }
          .products .products-menu .main-panel-group > .panel-default .panel-body > .panel-group .panel-default .panel-heading a i {
            position: absolute;
            right: 20px;
            color: #dddddd; }
          .products .products-menu .main-panel-group > .panel-default .panel-body > .panel-group .panel-default .panel-heading a[aria-expanded=true] i:before {
            content: "\e900"; }
      .products .products-menu .main-panel-group > .panel-default .panel-body ul li:not(:last-child) {
        border-bottom: 1px solid #dddddd; }
      .products .products-menu .main-panel-group > .panel-default .panel-body ul li a {
        font-family: "kelson_sanslight";
        font-size: 1.4rem;
        line-height: 2rem;
        color: #4b4b4b;
        padding: 10px 10px 10px 52px;
        display: block;
        position: relative;
        -webkit-transition: .3s ease-in-out;
        -moz-transition: .3s ease-in-out;
        -ms-transition: .3s ease-in-out;
        -o-transition: .3s ease-in-out;
        transition: .3s ease-in-out; }
        .products .products-menu .main-panel-group > .panel-default .panel-body ul li a:before {
          content: "\e904";
          font-family: 'icomoon';
          font-size: 9px;
          color: #dddddd;
          position: absolute;
          left: 35px;
          -webkit-transition: .3s ease-in-out;
          -moz-transition: .3s ease-in-out;
          -ms-transition: .3s ease-in-out;
          -o-transition: .3s ease-in-out;
          transition: .3s ease-in-out; }
        .products .products-menu .main-panel-group > .panel-default .panel-body ul li a:hover, .products .products-menu .main-panel-group > .panel-default .panel-body ul li a.active {
          color: #ffffff;
          background: #6d3b85; }
          .products .products-menu .main-panel-group > .panel-default .panel-body ul li a:hover:before, .products .products-menu .main-panel-group > .panel-default .panel-body ul li a.active:before {
            color: #ffffff; }

.products .product-detail .title {
  background: #ffffff;
  border: 1px solid #d9d9d9;
  padding: 15.5px 27px;
  display: table;
  width: 100%; }
  @media (max-width: 991px) {
    .products .product-detail .title {
      padding: 15.5px 15px; } }
  .products .product-detail .title h3 {
    font-family: "kelson_sansbold";
    font-size: 1.7rem;
    line-height: 2.4rem;
    color: #4b4b4b;
    display: inline-block;
    float: left;
    text-transform: uppercase; }
  .products .product-detail .title span {
    display: inline-block;
    float: left;
    font-family: "kelson_sanslight";
    font-size: 1.7rem;
    line-height: 2.4rem;
    color: #4b4b4b;
    position: relative;
    padding-left: 14px; }
    .products .product-detail .title span:before {
      content: "/";
      position: absolute;
      left: 5px; }

.products .product-detail figure {
  margin-top: 9px;
  border: 1px solid #d9d9d9;
  background: #ffffff;
  height: 292px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center; }
  .products .product-detail figure img {
    max-width: 100%;
    max-height: 100%; }

.products .product-detail section {
  background: #ffffff;
  border: 1px solid #d9d9d9;
  margin-top: 9px; }
  .products .product-detail section h3 {
    font-family: "kelson_sansbold";
    font-size: 1.7rem;
    line-height: 2.4rem;
    color: #4b4b4b;
    padding: 14px 27px;
    border-bottom: 1px solid #d9d9d9; }
    @media (max-width: 991px) {
      .products .product-detail section h3 {
        padding: 14px 15px; } }
  .products .product-detail section article {
    padding: 41px 50px;
    font-family: "Conv_Calibri";
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: #4b4b4b; }
    @media (max-width: 991px) {
      .products .product-detail section article {
        padding: 20px; } }
    .products .product-detail section article img {
      max-width: 100%;
      height: auto !important; }
  .products .product-detail section ul li:not(:last-child) {
    border-bottom: 1px solid #d9d9d9; }
  .products .product-detail section ul li a {
    font-family: "Conv_Calibri";
    font-size: 1.4rem;
    line-height: 2.4rem;
    color: #4b4b4b;
    display: block;
    padding: 15.5px 27px 15.5px 65px;
    position: relative;
    -webkit-transition: .3s ease-in-out;
    -moz-transition: .3s ease-in-out;
    -ms-transition: .3s ease-in-out;
    -o-transition: .3s ease-in-out;
    transition: .3s ease-in-out; }
    .products .product-detail section ul li a:hover {
      background: #faf6fc; }
    @media (max-width: 991px) {
      .products .product-detail section ul li a {
        padding: 15.5px 40px; } }
    .products .product-detail section ul li a i {
      font-size: 16px;
      color: #b345e6;
      position: absolute;
      left: 30px;
      top: 20px; }
      @media (max-width: 991px) {
        .products .product-detail section ul li a i {
          left: 15px; } }

/**************************PRODUCTS**************************/
/**************************CONTACT**************************/
.contact {
  max-width: 980px;
  margin: 0 auto; }
  .contact .contact-information h2 {
    font-family: "kelson_sansnormal";
    font-size: 5rem;
    line-height: 6rem;
    color: #6d3b85; }
    @media (max-width: 991px) {
      .contact .contact-information h2 {
        font-size: 3.5rem;
        line-height: 4.5rem; } }
  .contact .contact-information address {
    font-family: "kelson_sanslight";
    font-size: 1.8rem;
    line-height: 2.4rem;
    color: #4b4b4b;
    margin: 27px 0 16px 0; }
  .contact .contact-information ul li:not(:last-child) {
    margin-bottom: 14px; }
  .contact .contact-information ul li a {
    font-family: "kelson_sanslight";
    font-size: 1.8rem;
    line-height: 2.4rem;
    color: #4b4b4b;
    position: relative;
    padding-left: 36px; }
    .contact .contact-information ul li a i {
      font-size: 22px;
      color: #6d3b85;
      position: absolute;
      left: 0; }
  .contact .contact-form {
    max-width: 430px;
    margin: 0 auto; }
    @media (max-width: 991px) {
      .contact .contact-form {
        margin: 30px 0 0 0;
        max-width: 100%; } }
    .contact .contact-form div {
      position: relative;
      padding-bottom: 35px; }
      .contact .contact-form div label {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        font-family: "kelson_sansnormal";
        color: red; }
    .contact .contact-form input, .contact .contact-form textarea {
      padding: 8px 0;
      font-family: "kelson_sanslight";
      font-size: 1.6rem;
      line-height: 2.4rem;
      border-bottom: 1px solid #a2a2a2;
      width: 100%;
      color: #4b4b4b;
      background: none; }
      .contact .contact-form input::-webkit-input-placeholder, .contact .contact-form textarea::-webkit-input-placeholder {
        color: #4b4b4b; }
        .contact .contact-form input::-webkit-input-placeholder:focus, .contact .contact-form textarea::-webkit-input-placeholder:focus {
          color: #6d3b85; }
      .contact .contact-form input:-moz-placeholder, .contact .contact-form textarea:-moz-placeholder {
        color: #4b4b4b; }
        .contact .contact-form input:-moz-placeholder:focus, .contact .contact-form textarea:-moz-placeholder:focus {
          color: #6d3b85; }
      .contact .contact-form input::-moz-placeholder, .contact .contact-form textarea::-moz-placeholder {
        color: #4b4b4b; }
        .contact .contact-form input::-moz-placeholder:focus, .contact .contact-form textarea::-moz-placeholder:focus {
          color: #6d3b85; }
      .contact .contact-form input:-ms-input-placeholder, .contact .contact-form textarea:-ms-input-placeholder {
        color: #4b4b4b; }
        .contact .contact-form input:-ms-input-placeholder:focus, .contact .contact-form textarea:-ms-input-placeholder:focus {
          color: #6d3b85; }
      .contact .contact-form input:focus, .contact .contact-form textarea:focus {
        border-color: #6d3b85; }
    .contact .contact-form textarea {
      resize: none;
      height: 41px; }
    .contact .contact-form button {
      font-family: "kelson_sansnormal";
      font-size: 2rem;
      line-height: 2.6rem;
      color: #ffffff;
      background: #6d3b85;
      text-align: center;
      border-radius: 6px;
      width: 100%;
      padding: 18px 15px;
      margin-top: 30px; }
      .contact .contact-form button i {
        font-size: 22px;
        color: #ffffff;
        margin-right: 9px;
        margin-top: -3px; }
    .contact .contact-form p {
      font-family: "kelson_sansnormal";
      font-size: 1.6rem;
      line-height: 2rem;
      margin: 15px 0;
      display: none; }
      .contact .contact-form p.success {
        color: green;
        display: block; }
      .contact .contact-form p.error {
        color: red;
        display: block; }

/**************************CONTACT**************************/
/**************************COOKIE-POLICY**************************/
.cookie-policy {
  background: rgba(109, 59, 133, 0.9);
  padding: 19px 0;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  display: none; }
  .cookie-policy.active {
    display: block; }
  .cookie-policy .container {
    position: relative; }
  .cookie-policy p {
    font-family: "kelson_sanslight";
    font-size: 1.3rem;
    line-height: 1.6rem;
    width: 70%;
    color: #ffffff; }
    @media (max-width: 991px) {
      .cookie-policy p {
        width: 100%;
        padding-right: 50px; } }
    .cookie-policy p a {
      color: #ffffff;
      text-decoration: underline !important; }
  .cookie-policy a.cookie-close {
    display: inline-block;
    padding: 6px;
    background: #ffffff;
    border-radius: 4px;
    position: absolute;
    right: 15px;
    top: 0;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 1; }
    .cookie-policy a.cookie-close i {
      font-size: 15px;
      color: #6d3b85; }

/**************************COOKIE-POLICY**************************/
/**************************404**************************/
.error-404 {
  text-align: center;
  padding: 100px 0; }
  @media (max-width: 991px) {
    .error-404 {
      padding: 60px 0; } }
  @media (max-width: 767px) {
    .error-404 {
      padding: 40px 0; } }
  .error-404 h1 {
    font-family: "kelson_sansbold";
    font-size: 18rem;
    line-height: 17rem;
    color: #6d3b85; }
    @media (max-width: 991px) {
      .error-404 h1 {
        font-size: 14rem;
        line-height: 15rem; } }
  .error-404 h6 {
    font-family: "kelson_sansnormal";
    font-size: 3.5rem;
    line-height: 4.5rem;
    color: #4b4b4b; }
    @media (max-width: 991px) {
      .error-404 h6 {
        font-size: 2.8rem;
        line-height: 3.2rem; } }
  .error-404 p {
    font-family: "kelson_sanslight";
    font-size: 1.7rem;
    line-height: 2.4rem;
    color: #4b4b4b;
    margin-top: 6px; }
    @media (max-width: 991px) {
      .error-404 p {
        font-size: 1.6rem;
        line-height: 2rem; } }
    .error-404 p a {
      font-family: "kelson_sansnormal";
      color: #6d3b85;
      text-decoration: underline !important; }

/**************************404**************************/
/**************************STYLEGUIDE**************************/
.styleguide h1 {
  font-family: "kelson_sansbold";
  font-size: 3rem;
  line-height: 4rem;
  color: #6d3b85;
  margin-bottom: 10px; }

.styleguide ul li:not(:last-child) {
  margin-bottom: 10px; }

.styleguide ul li a {
  font-family: "kelson_sansnormal";
  font-size: 1.7rem;
  line-height: 2.4rem;
  color: #6d3b85;
  text-decoration: underline !important; }

/**************************STYLEGUIDE**************************/
