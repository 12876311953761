[class^="icon-"], [class*=" icon-"] {
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-shield:before {
  content: "\e917";
}
.icon-minus:before {
  content: "\e900";
}
.icon-plus:before {
  content: "\e916";
}
.icon-play:before {
  content: "\e901";
}
.icon-bottom:before {
  content: "\e902";
}
.icon-top:before {
  content: "\e903";
}
.icon-next:before {
  content: "\e904";
}
.icon-prev:before {
  content: "\e905";
}
.icon-close:before {
  content: "\e906";
}
.icon-pdf:before {
  content: "\e907";
}
.icon-word:before {
  content: "\e908";
}
.icon-link:before {
  content: "\e909";
}
.icon-location:before {
  content: "\e90a";
}
.icon-fax:before {
  content: "\e90b";
}
.icon-direction:before {
  content: "\e90c";
}
.icon-back:before {
  content: "\e90d";
}
.icon-instagram:before {
  content: "\e90e";
}
.icon-facebook:before {
  content: "\e90f";
}
.icon-twitter:before {
  content: "\e910";
}
.icon-send:before {
  content: "\e911";
}
.icon-comment:before {
  content: "\e912";
}
.icon-mail:before {
  content: "\e913";
}
.icon-phone-stroke:before {
  content: "\e914";
}
.icon-calendar:before {
  content: "\e915";
}
.icon-phone:before {
  content: "\e92d";
}