@font-face {
    font-family: 'kelson_sansbold';
    src: url('#{$fonts}/kelsonsans-bold-webfont.eot');
    src: url('#{$fonts}/kelsonsans-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/kelsonsans-bold-webfont.woff2') format('woff2'),
         url('#{$fonts}/kelsonsans-bold-webfont.woff') format('woff'),
         url('#{$fonts}/kelsonsans-bold-webfont.ttf') format('truetype'),
         url('#{$fonts}/kelsonsans-bold-webfont.svg#kelson_sansbold') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'kelson_sansnormal';
    src: url('#{$fonts}/kelsonsans-normal-webfont.eot');
    src: url('#{$fonts}/kelsonsans-normal-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/kelsonsans-normal-webfont.woff2') format('woff2'),
         url('#{$fonts}/kelsonsans-normal-webfont.woff') format('woff'),
         url('#{$fonts}/kelsonsans-normal-webfont.ttf') format('truetype'),
         url('#{$fonts}/kelsonsans-normal-webfont.svg#kelson_sansnormal') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'kelson_sanslight';
    src: url('#{$fonts}/kelsonsans-light-webfont.eot');
    src: url('#{$fonts}/kelsonsans-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/kelsonsans-light-webfont.woff2') format('woff2'),
         url('#{$fonts}/kelsonsans-light-webfont.woff') format('woff'),
         url('#{$fonts}/kelsonsans-light-webfont.ttf') format('truetype'),
         url('#{$fonts}/kelsonsans-light-webfont.svg#kelson_sanslight') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Conv_Calibri';
    src: url('#{$fonts}/Calibri.eot');
    src: local('☺'), 
        url('#{$fonts}/Calibri.woff') format('woff'),
        url('#{$fonts}/Calibri.ttf') format('truetype'),
        url('#{$fonts}/Calibri.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
  font-family: 'icomoon';
  src:  url('#{$fonts}/icomoon.eot?sy4dhr');
  src:  url('#{$fonts}/icomoon.eot?sy4dhr#iefix') format('embedded-opentype'),
        url('#{$fonts}/icomoon.ttf?sy4dhr') format('truetype'),
        url('#{$fonts}/icomoon.woff?sy4dhr') format('woff'),
        url('#{$fonts}/icomoon.svg?sy4dhr#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}